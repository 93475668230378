import React from 'react'
import Layout from '../../../components/layout/layout'
import SEO from '../../../components/seo'
import LeaguesCoordinators from '../../../components/leagues/leagues-coordinators/leagues-coordinators'

const LeaguesCoordinatorsPage = () => {
  return (
    <Layout>
      <SEO title={'Leagues coordinators'} />
      <LeaguesCoordinators />
    </Layout>
  )
}
export default LeaguesCoordinatorsPage
