import React, { FC, useMemo } from 'react'
import AdminTable, { DataCols, Props } from '../admin-table/admin-table'
import { GetAreaCoordinators_resourcesByEntityId_results as CoordinatorListItem } from '../../graphql-types/GetAreaCoordinators'
import { isInviteStateActive, ShowInviteStatus, ShowSafePlayStatus } from '../areas/area-helpers'
import ExpandedMenu from '../expanded-menu/expanded-menu'
import StatusLabel from '../status-label/status-label'
import { useTranslation } from 'react-i18next'

type CoordinatorsTableProps = Omit<
  Props<CoordinatorListItem & { id: string }>,
  'data' | 'columns'
> & {
  data: CoordinatorListItem[] | undefined
  type: 'area' | 'league'
  hideAreaLeagueColumn?: boolean
  onRemove?: (coorinator: CoordinatorListItem & { id: string }) => void
}

const CoordinatorsTable: FC<CoordinatorsTableProps> = ({
  data,
  controls,
  loading,
  onRemove,
  hideAreaLeagueColumn = false,
  type,
  ...remainingProps
}) => {
  const { t } = useTranslation()

  const coordinatorsList = useMemo(
    () =>
      data?.map((coordinator, index) => ({
        ...coordinator,
        id: coordinator.userId,
        key: `${coordinator.userId}${index}`
      })) ?? [],
    [data]
  )
  const showRoleLabels = coordinator => {
    return coordinator.SystemRoles?.map((role, index) => {
      if (role.Value === 1024) {
        return (
          <StatusLabel key={index} variety="success">
            {t('area coordinator')}
          </StatusLabel>
        )
      }
      return null
    })
  }
  const columns: DataCols<CoordinatorListItem & { id: string }> = [
    {
      key: 'name',
      getValue: item =>
        `${item?.person?.standardGivenName ?? ''} ${item?.person?.standardFamilyName ?? ''}`,
      title: t('coordinator name'),
      sort: false
    },
    {
      key: 'email',
      getValue: item => item?.person?.email ?? item?.userId,
      title: t('email address')
    },
    {
      key: 'role',
      getValue: item => showRoleLabels(item),
      title: t('role'),
      sort: false
    },
    {
      key: 'status',
      getValue: item => <ShowInviteStatus states={item.roles} />,
      title: t('invite status'),
      sort: false
    },
    ...(hideAreaLeagueColumn
      ? []
      : [
          {
            key: type,
            getValue: item => item?.organisation?.organisationName,
            title: t(type),
            sort: false
          }
        ]),
    {
      key: 'safePlay',
      getValue: item => <ShowSafePlayStatus coordinator={item} />,
      title: t('safeplay approval'),
      sort: false
    },
    {
      key: 'Actions',
      getValue: item =>
        isInviteStateActive(item.roles) ? (
          <ExpandedMenu
            items={[
              {
                key: 'remove',
                label: t('remove'),
                onClick: () => onRemove?.(item),
                warning: true
              }
            ]}
            buttonText={''}
            buttonIcon
            iconName="md-more-options"
            anchorElement="icon"
          />
        ) : null,
      title: t('actions')
    }
  ]

  return (
    <AdminTable
      data={coordinatorsList}
      columns={columns}
      controls={controls}
      loading={loading}
      {...remainingProps}
    />
  )
}

export default CoordinatorsTable
