import React, { FC, useState } from 'react'
import {
  GetLeaguesCoordinators,
  GetLeaguesCoordinatorsVariables
} from '../../../graphql-types/GetLeaguesCoordinators'
import { useControlledQuery } from '../../table-controls/table-controls'
import { meshGatewayClient } from '../../../apollo/client'
import { useOrgId } from '../../../apollo/local-state'
import { GET_LEAGUES_COORDINATORS } from '../leagues-queries'
import { PageMaxWidth } from '../../util-components/util-components'
import Panel from '../../panel/panel'
import CoordinatorsTable from '../../coordinators-table/coordinators-table'
import * as styles from '../leagues.module.less'
import TextInput from '../../input/input'
import { useTranslation } from 'react-i18next'
import InviteStatusFilter from '../../invite-status-filter/invite-status-filter'
import { Grid } from '@material-ui/core'
import { useDebounce } from '../../../hooks/use-debounce'

interface GetLeaguesCoordinatorsVariablesWithPagination extends GetLeaguesCoordinatorsVariables {
  offset: number
  limit: number
}
const LeaguesCoordinators: FC = () => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const [inviteStatus, setInviteStatus] = useState('')
  const searchFilter = useDebounce(search, 500)

  const orgId = useOrgId()

  const getSelectedFilters = () => {
    return {
      entityIds: [orgId],
      includeChildren: true,
      ...(searchFilter && { fullName: searchFilter }),
      ...(inviteStatus && { roleStates: [inviteStatus] })
    }
  }
  const { data: coordinators, controlProps, loading } = useControlledQuery<
    GetLeaguesCoordinators,
    GetLeaguesCoordinatorsVariablesWithPagination
  >(GET_LEAGUES_COORDINATORS, {
    client: meshGatewayClient,
    getTotalItems: (area: GetLeaguesCoordinators) => area.resourcesByEntityId?.total ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: (v: GetLeaguesCoordinatorsVariablesWithPagination) => {
      const { limit, offset } = v
      const filters = getSelectedFilters()

      return {
        filter: { ...filters },
        pageArgs: { limit, skip: offset }
      }
    }
  })
  return (
    <PageMaxWidth>
      <Panel>
        <Grid container alignItems={'center'}>
          <div className={styles.searchWrapper}>
            <TextInput
              placeholder={t('search name')}
              value={search}
              onChange={t => setSearch(t?.target.value)}
              disableUnderline
              outlined
              fullWidth
              onClearClick={search ? () => setSearch('') : undefined}
            />
          </div>
          <InviteStatusFilter status={inviteStatus} setStatus={setInviteStatus} />
        </Grid>
      </Panel>
      <Panel>
        <CoordinatorsTable
          data={coordinators?.resourcesByEntityId?.results}
          type={'league'}
          controls={controlProps}
          loading={loading}
        />
      </Panel>
    </PageMaxWidth>
  )
}

export default LeaguesCoordinators
