import React, { Dispatch, FC, SetStateAction } from 'react'
import { RoleState } from '../../graphql-types/globalUstaTypes'
import Dropdown from '../dropdown/dropdown'
import { useTranslation } from 'react-i18next'

type InviteStatusFilterProps = {
  status?: string
  setStatus?: Dispatch<SetStateAction<string>>
}
const InviteStatusFilter: FC<InviteStatusFilterProps> = ({ status, setStatus }) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      options={[
        { value: '', label: t('any invite status') },
        { value: RoleState.PENDING, label: t('invited') },
        { value: RoleState.ACTIVE, label: t('registered') },
        { value: RoleState.EXPIRED, label: t('expired') },
        { value: RoleState.REVOKED, label: t('revoked') }
      ]}
      selected={status}
      onSelect={option => setStatus?.(option.value)}
    />
  )
}

export default InviteStatusFilter
